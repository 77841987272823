/**
 * External Dependencies
 */
import React from 'react';
import classNames from 'classnames';

export const DotButton = ({ selected, onClick }) => (
  <button
    className={classNames('carousel__dot clear-btn', { 'is-selected': selected })}
    onClick={onClick}
  >
  </button>
);

export const PrevButton = ({ onClick }) => (
  <button 
    className="carousel__button carousel__button--prev clear-btn bg-blue position-relative d-flex justify-content-center align-items-center" 
    onClick={onClick}
  >
    <i className="icon icon--arrow-left" />
  </button>
);

export const NextButton = ({ onClick }) => (
  <button
    className="carousel__button carousel__button--next clear-btn bg-blue position-relative d-flex justify-content-center align-items-center"
    onClick={onClick}
  >
    <i className="icon icon--arrow-right" />
  </button>
);