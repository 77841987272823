import React from 'react';
import { Form } from 'react-bootstrap';

const TextField = (props) => {
    const { label,  ...rest } = props;
    return (
        <Form.Group className="form-wrap" controlId={label}>
            <Form.Label>{label}</Form.Label>
            <Form.Control as="textarea" {...rest} />
        </Form.Group>
    )

}
export default TextField;