/**
 * External Dependencies
 */
import React, { useCallback, useState, useEffect } from "react"
import useEmblaCarousel from "embla-carousel-react"
import classNames from "classnames"
import { Container, Row, Col } from "react-bootstrap"

/**
 * Internal Dependencies
 */
import { PrevButton, NextButton, DotButton } from "./carousel-buttons"
import "./styles/_index.scss"

const Carousel = ({
  className,
  container = "",
  isFluidContainer = true,
  data = [],
  index = 0,
  gallery,
  setPlaying,
  controllers = {
    buttons: false,
    dots: false,
    progress: false,
  },
}) => {
  const [selectedIndex, setSelectedIndex] = useState(index)
  const [scrollSnaps, setScrollSnaps] = useState([])

  const [emblaRef, embla] = useEmblaCarousel({
    align: "start",
    loop: true,
    skipSnaps: false,
    inViewThreshold: 1,
    containScroll: "trimSnaps",
    startIndex: index,
  })

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])
  const scrollTo = useCallback(index => embla && embla.scrollTo(index), [embla])

  const onSelect = useCallback(() => {
    if (!embla) return
    setSelectedIndex(embla.selectedScrollSnap())
  }, [embla, setSelectedIndex])

  useEffect(() => {
    if (!embla) return
    setScrollSnaps(embla.scrollSnapList())
    embla.on("select", onSelect)
  }, [embla, setScrollSnaps, onSelect])

  useEffect(() => {
    if (setPlaying) {
      setPlaying(gallery && gallery[selectedIndex]?.type === "video")
    }
  }, [selectedIndex, gallery, setPlaying])

  return (
    <div className={classNames("carousel", className)}>
      <Container fluid={isFluidContainer && 'hg'} className={container && `container-${container}`}>
        <div className="carousel__viewport" ref={emblaRef}>
          <div className="carousel__container d-flex">
            {data.map((slide, index) => (
              <div className="carousel__slide" key={index}>
                {slide}
              </div>
            ))}
          </div>
        </div>

        <Row className="carousel__actions g-0 justify-content-between">
          {controllers.buttons && (
            <Col xs="auto" className="carousel__buttons d-flex">
              <PrevButton onClick={scrollPrev} />
              <NextButton onClick={scrollNext} />
            </Col>
          )}

          {controllers.dots && (
            <Col
              xs="auto"
              className="carousel__dots d-flex d-lg-none align-items-center"
            >
              {scrollSnaps.map((_, index) => (
                <DotButton
                  key={index}
                  selected={index === selectedIndex}
                  onClick={() => scrollTo(index)}
                />
              ))}
            </Col>
          )}

          {controllers.progress && (
            <Col
              xs="auto"
              className="carousel__progress d-flex justify-content-center align-items-center align-self-end"
            >
              <div className="carousel__progress-inner">
                <span>
                  {selectedIndex + 1} / {data.length}
                </span>
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  )
}

export default Carousel
