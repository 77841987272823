/**
 * External dependencies
 */
import React from "react"
import { Link } from "gatsby"
import classNames from "classnames"

/**
 * Internal dependencies
 */
import "./styles/_index.scss"
import GetURL from "../../components/common/site/get-url"
const LinkIcon = ({
  className,
  href = "/",
  text,
  icon = "arrow-up-right",
  order = "0",
  customLink,
  Link_Type
}) => (
  <GetURL customLink={customLink}
                            class="link-icon blue-link-hover d-flex align-items-center"
                            label={""}
                            alias={href}
                            Link_Type={Link_Type}
                          >
                            
                             <div
      className={`link-icon__text fw-bolder fz14 position-relative order-${order}`}
    >
      {text}
    </div>
    <i className={classNames(`icon icon--${icon}`)}></i>
                          </GetURL>
  // <Link
  //   to={href}
  //   className={classNames("link-icon blue-link-hover d-flex align-items-center", className)}
  // >
  //   <div
  //     className={`link-icon__text fw-bolder fz14 position-relative order-${order}`}
  //   >
  //     {text}
  //   </div>
  //   <i className={classNames(`icon icon--${icon}`)}></i>
  // </Link>
)

export default LinkIcon
