/**
 * External Dependencies
 */
import React, { useEffect, useState } from "react"
import ReactPlayer from "react-player"
import { GatsbyImage } from "gatsby-plugin-image"
import classNames from "classnames"
import ImageTransform from "../../components/common/ggfx-client/module/components/image-transform"
/**
 * Internal Dependencies
 */
import Button from "../button/button"
import Portal from "../portal/portal"
import "./styles/_index.scss"
import {
  isMobileOnly,
  isTablet
} from "react-device-detect";
import Noimg from "../../components/no-img/Noimg"
import { Link } from "gatsby"
const VideoPlaceholder = ({
  image,
  imageClassName,
  alt,
  video,
  bg,
  nowrapper,
  disableLazyLoadOnImage,
  playBtnOnRight = false,
  title,
  btnText,
  imagetransforms,
  Pagename,
  id,
  btnIcon,
  imgname,
  href,
  onClick,
  displayAsLink,
  relative,
  BG_Video_Desktop,
  BG_Video_Mobile
}) => {
  const [isPortalOpen, setIsPortalOpen] = useState(false)
  const [play, setPlayVideo] = useState(false)
  const playVideo = () => setPlayVideo(true)

  const handleClick = () => {
    setIsPortalOpen(true)
    playVideo()
  }

  const trackerVideo = (event) => {
    if (typeof window !== "undefined"){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'Video Play Btn',
        'formType': event,
        'formId': 'Video Play',
        'formName': 'Video',
        'formLabel': 'Video'
      });
    }
    
}


  const closeOnEsc = e => e.key === "Escape" && setIsPortalOpen(false)

  useEffect(() => {
    window.addEventListener("keydown", closeOnEsc)
    return () => window.removeEventListener("keydown", closeOnEsc)
  }, [])

  let processedImages = imagetransforms


  return (
    <div
      className={classNames("video-placeholder", {
        "play-btn-right": playBtnOnRight,
        "has-content": title,
      })}
    >
      <div
        className={classNames("h-100 video-placeholder__image", {
          "w-100 position-absolute": title,
          "position-relative" : relative
        })}
        role="presentation"
        // onClick={handleClick}
        // onKeyDown={handleClick}
      >
        {/* <GatsbyImage
          loading={disableLazyLoadOnImage ? "eager" : "lazy"}
          image={image}
          imgClassName={imageClassName}
          alt={alt || "palceholder"}
          objectPosition="50% 0"
        /> */}
        {/* <img src={image} /> */}
        {/* {JSON.stringify(image)} */}
        {BG_Video_Desktop &&
                <div className="html-vid">
                <ReactPlayer 

url={isMobileOnly || isTablet ? BG_Video_Mobile : BG_Video_Desktop}
muted={true}
loop={true}
controls={false}
autoplay={true}
playsinline={true}
playing={true}
onPlay={trackerVideo(title)}
/>
</div>
        }
        {image ?
<>
{nowrapper ?
  <Link to={href}>
        <ImageTransform
                                    imagesources={image?.url}
                                    renderer={bg ? "bgImg" : "srcSet"}
                                    imagename={imgname}
                                    attr={{
                                      alt: alt
                                        ? alt
                                        : Pagename + " - Strakers",
                                      className: "",
                                    }}
                                    imagetransformresult={processedImages}
                                    id={id}
                                  ></ImageTransform>
                                  </Link>
                                  :
        
                                  <div className="gatsby-image-wrapper">
                                    <Link to={href}>
                                  <ImageTransform
                                    imagesources={image?.url}
                                    renderer={bg ? "bgImg" : "srcSet"}
                                    imagename={imgname}
                                    attr={{
                                      alt: alt
                                        ? alt
                                        : Pagename + " - Strakers",
                                      className: "",
                                    }}
                                    imagetransformresult={processedImages}
                                    id={id}
                                  ></ImageTransform></Link>
                                                            </div>
}
                                  </>
                                  
    :
    <div className="gatsby-image-wrapper">
    <Noimg imagename="coming-soon-image.Image.detailbig" />
    </div>
    }
      </div>
      {title && (
        <div className="video-placeholder__content d-flex flex-column position-relative justify-content-between h-100">
          <h5>{title}</h5>
          {btnText && (
            <Button onClick={onClick} displayAsLink={displayAsLink} showTextOnMobileOnly={true}>
              <Button.Icon>
                <i className={`icon icon--${btnIcon}`}></i>
              </Button.Icon>

              <Button.Text>{btnText}</Button.Text>
            </Button>
          )}
        </div>
      )}
      {video &&
      <div
        className={classNames(
          `play-icon d-flex justify-content-center align-items-center position-absolute`,
          {
            "top-50 start-50 translate-middle": !playBtnOnRight,
          }
        )}
        role="presentation"
        onClick={handleClick}
        onKeyDown={handleClick}
      >
        <i className="icon icon--triangle"></i>
      </div>}
      <Portal
        onClose={() => {
          setIsPortalOpen(false)
        }}
        isOpen={isPortalOpen}
      >
        <ReactPlayer
          frameBorder="0"
          allowFullScreen
          url={video}
          modestbranding="1"
          controls={true}
          autoPlay={true}
          muted={false}
          playsinline={true}
          playing={play}
          width="100%"
          height="100vh"
          previewTabIndex={0}
          light={false}
        />
      </Portal>
    </div>
  )
}

export default VideoPlaceholder
