import React from 'react';
import { Form } from 'react-bootstrap';

const TextField = (props) => {
    const { type = "text", isCurrency = false, label, ...rest  } = props;
    return (
        <Form.Group className='form-wrap'>
            {label &&<Form.Label>{label}</Form.Label>}
            <Form.Control type={type} {...rest} />
            {isCurrency &&
                <i className='icon-fix'></i>
            }

        </Form.Group>
    )

}
export default TextField;