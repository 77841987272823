/**
 * External dependencies
 */
 import React from "react"
 import ImageTransform from "@components/common/ggfx-client/module/components/image-transform"; 
 import { useStaticQuery, graphql } from "gatsby" 
 const Noimg = (props) => {
    const data = useStaticQuery(graphql`
    query {
      glstrapi {
        comingSoonImage(publicationState: LIVE) {
            id
            imagetransforms
            Image {
              url
              alternativeText
            }
          }
    }
}
`)
let processedImages = JSON.stringify({});
if (data?.glstrapi?.comingSoonImage?.imagetransforms?.Image_Transforms) {
  processedImages = data?.glstrapi?.comingSoonImage?.imagetransforms?.Image_Transforms;
}
  return(
    <ImageTransform
    imagesources={data?.glstrapi?.comingSoonImage?.Image?.url}
    renderer="srcSet"
    imagename={props?.imagename}
    attr={{ alt: `No Image - Strakers Estate Agents`, className: props?.class ? props?.class : "no-image-style" }}
    imagetransformresult={processedImages}
    id={data?.glstrapi?.comingSoonImage?.id}
  />
  )  
 } 
 
 export default Noimg
 
