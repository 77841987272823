import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";

const Recaptcha = ({ recaptchaRef}) => {

    return (
        <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={process.env.GATSBY_RECAPTCHA_KEY} //
        />
    )

}
export default Recaptcha;