/**
 * External dependencies
 */
import React from 'react'
import classNames from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from "gatsby"
/**
 * Internal dependencies
 */
import VideoPlaceholder from '../video-placeholder/video-placeholder';
import './styles/card/_index.scss';
import { template } from 'lodash';

const Card = ({ className, children }) => {
  return (
    <div className={classNames('card', className)}>
      {children}
    </div>
  )
}

export default Card;

const CardHead = ({ imgname, imagetransforms, id, onImageHoverZoom ,image, video, alt, href, playBtnOnRight = false }) => {
  let processedImages = imagetransforms
  return (
    <div className={classNames('card__head', {
      'hover-zoom': onImageHoverZoom
    })}>
        <VideoPlaceholder
          image={image}
          imagetransforms={processedImages}
          id={id}
          video={video}
          alt={alt}
          href={href}
          playBtnOnRight={playBtnOnRight}
          imgname={imgname}
        />
  
    </div>
  )
};

const CardBody = ({ children }) => <div className="card__body">{children}</div>;

const CardFooter = ({ addBorderTop = true, children }) => (
  <div className="card__footer">
    <div className={classNames('card__footer-inner', {
      'border-top': addBorderTop
    })}>
      {children}
    </div>
  </div>
);

const CardDelimiter = () => <div className="card__delimiter"></div>

Card.Head = CardHead;
Card.Body = CardBody;
Card.Footer = CardFooter;
Card.Delimiter = CardDelimiter